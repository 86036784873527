import NextHead from 'next/head';
import Script from 'components/Script';
import { page_title } from 'assets/data/data';

const Head = ({ title, og_url, meta_tags, scripts, hreflang }) => {
    return (
        <>
            <NextHead>
                <title>{title && `${title} | ${page_title[process.env.KONTENT_COLLECTION] || 'Edwards Lifesciences'}`}</title>
                <meta property="og:title" content={title} />
                <meta property="og:url" content={og_url} />
                {meta_tags?.length > 0 &&
                    meta_tags?.map((tag, key) => {
                        const { type, name, content } = tag;
                        if (type === 'name') {
                            return (
                                <meta key={key} name={name} content={content} />
                            );
                        } else {
                            return (
                                <meta
                                    key={key}
                                    property={name}
                                    content={content}
                                />
                            );
                        }
                    })}

                {hreflang && (
                    <>
                        {hreflang?.canonical?.url && (
                            <link
                                rel="canonical"
                                href={hreflang.canonical.url}
                            />
                        )}
                        {hreflang?.alternate?.map((item) => {
                            return (
                                <link
                                    key={item.code}
                                    rel="alternate"
                                    href={item.url}
                                    hrefLang={item.code}
                                />
                            );
                        })}
                    </>
                )}
            </NextHead>
            {scripts?.length > 0 &&
                scripts?.map((script, key) => {
                    const { script_id, type, strategy, content } = script;
                    return (
                        <Script
                            key={key}
                            script_id={script_id}
                            type={type}
                            strategy={strategy}
                            content={content}
                        />
                    );
                })}
        </>
    );
};

export default Head;
