import SocialLinks from '../elements/SocialLinks';
import FooterLink from '../elements/FooterLink';
import CountrySelect from '../elements/CountrySelect';
import classNames from 'classnames';
import Link from 'components/Link';
import { Fragment } from 'react';
import ConsentBlackbar from 'components/ConsentBlackbar';
import TrustArc from '../elements/TrustArc';
import Image from 'next/image';

const Footer1a = ({
    id,
    codename,
    theme,
    spacing_top,
    spacing_bottom,
    copyright,
    bottom_links,
    social_text,
    social_links,
    countries,
    categories,
    cookie_preferences,
    personal_information,
    logo,
    column_span,
}) => {
    return (
        <footer
            className={classNames('footer', {
                [`footer--${theme}`]: theme,
                spacing_top,
                spacing_bottom,
            })}
            data-kontent-item-id={id}
        >
            <ConsentBlackbar />
            <div className="container">
                <div className="grid-md-2 grid-1 row-gap-9">
                    <div className="social">
                        <div className="social__text">{social_text}</div>
                        <SocialLinks social_links={social_links} />
                    </div>
                    {countries && countries.length > 0 && (
                        <CountrySelect countries={countries} />
                    )}
                </div>
                {(social_links || countries) && <hr />}
                {categories && (
                    <div className="grid-md-12 col-gap-7 row-gap-6">
                        {categories.map((category, index) => {
                            const { id, codename, title, links } = category;
                            return (
                                <Fragment key={index}>
                                    <div
                                        className="col-md-6"
                                        data-kontent-item-id={id}
                                        data-kontent-element-codename={codename}
                                    >
                                        <h5>{title}</h5>
                                        <div className="grid-lg-12 gap-0 gap-lg-7">
                                            {[1, 2].map((number) => (
                                                <div
                                                    className={classNames(
                                                        {
                                                            'col-md-4':
                                                                column_span ===
                                                                'two',
                                                        },
                                                        {
                                                            'col-md-6':
                                                                column_span ===
                                                                'three',
                                                        }
                                                    )}
                                                    key={number}
                                                >
                                                    <ul className="footer__list">
                                                        {links.map(
                                                            (item, index) => {
                                                                const {
                                                                    title,
                                                                    url,
                                                                    col,
                                                                } = item;
                                                                return (
                                                                    col ===
                                                                        number && (
                                                                        <FooterLink
                                                                            key={
                                                                                index
                                                                            }
                                                                            title={
                                                                                title
                                                                            }
                                                                            url={
                                                                                url
                                                                            }
                                                                            col={
                                                                                col
                                                                            }
                                                                        />
                                                                    )
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                )}
                {bottom_links && <hr />}
                <div
                    className="footer__bottom"
                    data-kontent-element-codename={codename}
                >
                    <div className="footer__bottom-content">
                        {copyright && (
                            <div className="footer__bottom-text">
                                &copy; {new Date().getFullYear()} {copyright}
                            </div>
                        )}
                        <div className="footer__bottom-links">
                            {bottom_links?.map((links, index) => {
                                const { text, url } = links;
                                return (
                                    <Link
                                        href={url}
                                        key={index}
                                        className="footer__bottom-link"
                                    >
                                        {text}
                                    </Link>
                                );
                            })}
                            <TrustArc
                                cookie_preferences={cookie_preferences}
                                personal_information={personal_information}
                            />
                        </div>
                    </div>
                    {logo && (
                        <div>
                            <Link href={logo?.href}>
                                <Image
                                    src={logo?.src}
                                    alt="logo"
                                    width={logo?.width}
                                    height={logo?.height}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer1a;
