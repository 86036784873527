import { useEffect, useState } from 'react';
import ccpa from 'assets/images/ccpa.svg';
import Image from 'next/image';

// Define an expiration date for the user's preference cookie, set to 24 hours from current time
const expirationDate = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
).toISOString();

const TrustArc = ({ cookie_preferences, personal_information }) => {
    // Initialize state for whether or not the user has opted out of data selling/sharing
    const [doNotSell, setDoNotSell] = useState(null);

    // Define an effect hook that will run once when the component is mounted
    useEffect(() => {
        // Check if the user's opt-out preference is already stored in local storage
        const do_not_sell = localStorage.getItem('do_not_sell');

        // Define a function that will use a third-party library to determine the user's location
        const getLocation = () => {
            if (typeof geoip2 !== 'undefined')
                geoip2.city(
                    (e) => {
                        // Extract the user's country and state from the location data
                        const country = e.country.iso_code;
                        // If the user is located in a valid US state, store their opt-out preference as true
                        if (country === 'US') {
                            localStorage.setItem(
                                'do_not_sell',
                                JSON.stringify({
                                    value: true,
                                    expires: expirationDate,
                                })
                            );
                            setDoNotSell(true);
                            // If the user is not located in a valid US state, store their opt-out preference as false
                        } else {
                            localStorage.setItem(
                                'do_not_sell',
                                JSON.stringify({
                                    value: false,
                                    expires: expirationDate,
                                })
                            );
                            setDoNotSell(false);
                        }
                    },
                    (error) => console.error(error)
                );
        };

        // If the user's opt-out preference is not stored in local storage, determine their location and store their preference
        if (do_not_sell === null) {
            return getLocation();
        }

        // If the user's preference has expired, remove it from local storage and determine their location to store a new preference
        const dateOfExpiration = new Date(
            Date.parse(JSON.parse(do_not_sell).expires)
        );
        if (dateOfExpiration < new Date()) {
            localStorage.removeItem('do_not_sell');
            return getLocation();
        }

        // If the user has previously opted out of data sharing/selling, set their state to reflect that
        if (JSON.parse(do_not_sell).value === true) {
            return setDoNotSell(true);
        }

        // If the user has previously opted in to data sharing/selling, set their state to reflect that
        if (JSON.parse(do_not_sell).value === false) {
            return setDoNotSell(false);
        }
    }, []);
    return (
        <>
            {cookie_preferences && (
                <button
                    className="footer__bottom-link"
                    onClick={() => {
                        truste.eu.clickListener();
                    }}
                >
                    {cookie_preferences}
                </button>
            )}
            {personal_information && doNotSell && (
                <button
                    className="footer__bottom-link footer__bottom-link--with-icon"
                    onClick={() => {
                        truste.eu.irmClickListener();
                    }}
                >
                    {personal_information}
                    <Image src={ccpa} alt="CCPA Opt-Out Icon" />
                </button>
            )}
        </>
    );
};
export default TrustArc;
