import algoliasearch from 'algoliasearch/lite';
import { custom_release_locales } from 'assets/data/data';
const News_Release_Countries = [
    'ca-en',
    'mx',
    'gb',
    'ch-en',
    'cr',
    'pr',
    'sg',
    'ca-fr',
    'kr',
    'jp',
];

const Orchard_Countries = [
    'de'
];

const client = algoliasearch(
    process.env.ALGOLIA_APPLICATION_ID,
    process.env.ALGOLIA_SEARCH_API_KEY
);

export const getSearchResults = (hits) => {
    let results = [];
    if (hits && hits.length > 0) {
        hits.forEach((hit) => {
            if (hit.title) {
                hit.url_slug = !hit.url_slug ? '/' : hit.url_slug;
                results.push({
                    ...hit,
                    label: hit.title,
                    value: hit.objectID,
                });
            }
        });
    }
    return results;
};

export const getAlgoliaSearchCalls = (language) => {
    if (language === 'default')
        return [
            client.initIndex(`${language}-language-pages`).search,
            client.initIndex('press-releases').search,
        ];
    else if (News_Release_Countries.includes(language))
        return [
            client.initIndex(`${language}-language-pages`).search,
            custom_release_locales.includes(language)
                ? client.initIndex(`${language}-press-releases`).search
                : client.initIndex('press-releases').search,
        ];
    else if (Orchard_Countries.includes(language))
        return [
            client.initIndex(`${language}-language-pages`).search,
            client.initIndex(`${language}-pages-1.0`).search,
            client.initIndex('press-releases').search,
        ];
    else return [client.initIndex(`${language}-language-pages`).search];
};
